import React, { useState, useContext, useEffect, useCallback } from "react"
import { QuoteContext } from "../Context/QuoteContext"
import { loadDocument } from "../Context/Actions"
import styled from "@emotion/styled"
import Loader from "../components/GlobalAssets/Loader"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import { isGuid, isNullOrWhitespace } from "../utils/HelperFunctions"
import { useTheme } from "emotion-theming"
import { Section } from "../components/PageComponents/Section"
import Icon from "../components/Icon/Icon"
import "url-search-params-polyfill"
import { Document, Page } from "react-pdf"

const LoadDocumentPage = ({ location }) => {
  const { state, dispatch } = useContext(QuoteContext)
  const [invalidGuid, setInvalidGuid] = useState(false)
  const theme = useTheme()
  const stableDispatch = useCallback(dispatch, [])

  const params = new URLSearchParams(location.search)
  const id = params.get("id")

  useEffect(() => {
    if (isNullOrWhitespace(id) || !isGuid(id)) {
      setInvalidGuid(true)
      return
    }

    stableDispatch(loadDocument({ id: id }))
  }, [stableDispatch, id])

  if (state.document.failed || invalidGuid) {
    return (
      <Layout>
        <SEO title="evoli - Något gick fel" noIndex={true} />

        <Section bgColor="#ffffff">
          <ContentContainer>
            <Icon
              name="stop"
              bgColor={theme.colors.CardContainer.iconBackground}
              iconColor={theme.colors.CardContainer.iconColor}
              iconSize="25"
            />
            <ContentHeader>Vi kunde inte hämta ditt dokument</ContentHeader>
            <ContentParagraph>
              {(state.hasFetchedDocument || invalidGuid) &&
                "Prova att öppna länken igen, om felet kvarstår kontakta vår support."}
            </ContentParagraph>

            <OutlinedButton href="https://support.evoli.se">
              Gå till support
            </OutlinedButton>
          </ContentContainer>
        </Section>
      </Layout>
    )
  }

  if (state.hasFetchedDocument && !state.isloading)
    return (
      <Document file={JSON.parse(state.document.content)} renderMode="canvas">
        <Page pageNumber={1} />
      </Document>
    )

  return (
    <>
      <SEO title="evoli - Hämtar dokument" noIndex={true} />
      <Loader title="Laddar ditt dokument" />
    </>
  )
}

export default LoadDocumentPage

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 320px;
  min-height: 300px;
`
const ContentHeader = styled.h1`
  margin: 0;
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ff4984;
  max-width: 250px;
`
const ContentParagraph = styled.p`
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #70669b;
  margin-bottom: 0;
  max-width: 250px;
`
const OutlinedButton = styled.a`
  border: 1px solid #01cc86;
  padding: 10px 20px;
  border-radius: 8px;
  background: white;
  color: #01cc86;
  font-size: 12px;
  margin-top: 30px;
  cursor: pointer;
`
